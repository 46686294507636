import React, { useState, useMemo } from "react";
import Pagination from "./Pagination";
import { useSelector } from "react-redux";
import Styles from "../styles/FiiDiiStyles.module.css";
import moment from "moment";

let PageSize = 12;
const CashMarketDailySummary = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const reduxFiiDii = useSelector((state) => state.fii.fiiDii);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return reduxFiiDii.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, reduxFiiDii]);

    const createSummaryBodyRows = () => {
        return (
            <tbody className={Styles.summaryTableBody}>
                {currentTableData.map((item) => {
                    return (
                        <tr>
                            <td>{moment(item.Date).format("DD MMM, ddd")}</td>
                            <td>{item.FII_BUY}</td>
                            <td>{item.FII_SELL}</td>
                            <td
                                className={
                                    parseFloat(item?.FII_NET) > 0
                                        ? Styles.positiveNet
                                        : Styles.negativeNet
                                }
                            >
                                {item.FII_NET}
                            </td>
                            <td>{item.DII_BUY}</td>
                            <td>{item.DII_SELL}</td>
                            <td
                                className={
                                    parseFloat(item?.DII_NET) > 0
                                        ? Styles.positiveNet
                                        : Styles.negativeNet
                                }
                            >
                                {item.DII_NET}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    return (
        <div className={Styles.cashMarketDailySummary}>
            <div className={Styles.summaryContainer}>
                <div className={Styles.summaryTitle}>
                    Cash Market Daily Summary
                </div>
                <table className={Styles.summaryTable}>
                    <thead>
                        <tr className={Styles.summaryTableHeader}>
                            <th>Date</th>
                            <th>FII Cash Buy</th>
                            <th>FII Cash Sell</th>
                            <th>FII Cash Net</th>
                            <th>DII Cash Buy</th>
                            <th>DII Cash Sell</th>
                            <th>DII Cash Net</th>
                        </tr>
                    </thead>
                    {createSummaryBodyRows()}
                </table>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={reduxFiiDii.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            </div>
        </div>
    );
};

export default CashMarketDailySummary;
