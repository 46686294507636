import React, { useEffect } from "react";
import FiiDiiNiftyChart from "./charts/FiiDiiNiftyChart";
import Styles from "../styles/CashMarketLongTermStyles.module.css";
import IndicesSummary from "./IndicesSummary";
import { indiceListTotal, indicesUrlMap } from "../data/Constants";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updatePerDay } from "../redux/fiiSlice";
import CashMarketDailySummary from "./CashMarketDailySummary";

const CashMarketLongTerm = () => {
    const indiceSummary = useSelector((state) => state.fii.perDay);
    const dispatch = useDispatch();

    useEffect(() => {
        let index = 0;
        const fetchTodaySpotData = async (spotDataUrl) => {
            try {
                const response = await axios.request({
                    url: spotDataUrl,
                    method: "GET",
                    headers: {
                        Authorization:
                            "Basic bmlmdHlhcGl1c2VyOm5pZnR5YXBpdXNlckAyMTEwIw==",
                    },
                });
                // const indice = response.data.result.symbol_name;
                // temp[indice] = response.data.result;
                dispatch(updatePerDay(response.data.result));
            } catch (error) {
                // console.error(error);
            }
        };
        for (index in indiceListTotal) {
            fetchTodaySpotData(indicesUrlMap[indiceListTotal[index]]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={Styles.cashMarketLongTerm}>
            <div className={Styles.longTermContainer}>
                <div className={Styles.longTermInnerContainer}>
                    <FiiDiiNiftyChart />
                </div>
            </div>
            <div className={Styles.indexSummary}>
                {indiceListTotal.map((data) => (
                    <IndicesSummary
                        name={data}
                        lastPrice={indiceSummary[data].last_trade_price}
                        change={indiceSummary[data].change_value}
                        changePercent={indiceSummary[data].change_per}
                        key={data}
                    />
                ))}
            </div>
            <CashMarketDailySummary />
        </div>
    );
};

export default CashMarketLongTerm;
