import "./App.css";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import Homepage from "./pages/Homepage";
import Navbar from "./layout/Navbar";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import OptionChain from "./pages/OptionChain";
// import FIIFnO from "./components/FIIFnO";
import ThunderOne from "./pages/ThunderOne";
import FIIBase from "./pages/FIIBase";
import Register from "./pages/Register";

function App() {
    return (
        <Router>
            <div className="App">
                <Navbar />
                <Routes>
                    <Route exact path="/" element={<Homepage />} />
                    <Route exact path="/thunderone" element={<ThunderOne />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/signup" element={<Signup />} />
                    <Route
                        exact
                        path="/option-chain"
                        element={<OptionChain />}
                    />
                    <Route exact path="/fii-dii" element={<FIIBase />} />
                    <Route exact path="/register" element={<Register />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
