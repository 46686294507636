import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
// eslint-disable-next-line
import Chart from "chart.js/auto";
import { useSelector } from "react-redux";
import { NIFTYPERDAYURL } from "../../data/Constants";
import axios from "axios";
import moment from "moment";

const chartOptions = {
    barThickness: 20,
    maintainAspectRatio: false,
    interaction: {
        mode: "index",
        intersect: false,
    },
    plugins: {
        title: {
            display: true,
            text: "Daily FII BUY/SELL in Index Future",
            color: "#FFFFFF",
            font: {
                size: "30vh",
                weight: "normal",
            },
        },
        legend: {
            labels: {
                color: "#FFFFFF",
                font: {
                    size: "15vh",
                    weight: "normal",
                },
            },
        },
    },
    scales: {
        yLeft: {
            type: "linear",
            display: true,
            position: "left",
            beginAtZero: false,
            ticks: {
                color: "#E2DFD2",
            },
        },
        yRight: {
            type: "linear",
            display: true,
            position: "right",
            ticks: {
                color: "#E2DFD2",
            },
        },
        x: {
            ticks: {
                color: "#E2DFD2",
            },
        },
    },
};

const FiiFutureChart = () => {
    const reduxFiiFno = useSelector((state) => state.fii.fiiFnO);
    const [labels, setLabels] = useState([]);
    const [datasets, setDatasets] = useState([]);

    useEffect(() => {
        const fetchNiftyData = async () => {
            try {
                const response = await axios.request({
                    url: NIFTYPERDAYURL,
                    method: "GET",
                });
                let data = response.data;
                setLabels(
                    reduxFiiFno.FII?.slice(0, 20)
                        .map((key) => moment(key.Date).format("DD MMM"))
                        .reverse()
                );
                setDatasets({
                    FII_Future: reduxFiiFno.FII?.slice(0, 20)
                        .map((key) => key.Future_Qty_Oi)
                        .reverse(),
                    NIFTY: data
                        ?.slice(0, 20)
                        .map((key) => key.Close)
                        .reverse(),
                });
            } catch (error) {
                // console.error(error);
            }
        };
        fetchNiftyData();
        // eslint-disable-next-line
    }, []);

    return (
        <div
            style={{
                height: "50vh",
                background: "#1e2124",
                margin: "auto",
                padding: "2vw",
            }}
        >
            <Bar
                datasetIdKey="id"
                options={chartOptions}
                data={{
                    labels: labels,
                    datasets: [
                        {
                            id: 1,
                            label: "NIFTY",
                            data: datasets.NIFTY,
                            type: "line",
                            yAxisID: "yLeft",
                            backgroundColor: "#d256f1",
                            borderColor: "#d256f1",
                        },
                        {
                            id: 2,
                            label: "FII Future",
                            data: datasets.FII_Future,
                            yAxisID: "yRight",
                            backgroundColor: "#6d67e4",
                        },
                    ],
                }}
            />
        </div>
    );
};

export default FiiFutureChart;
