import { createSlice } from "@reduxjs/toolkit";
import { DefaultIndicesData, DummyDataForIndices } from "../../data/Constants";

const initialState = {
    dummyData: DummyDataForIndices,
    nifty: {
        data: DefaultIndicesData,
        strikePrice: [],
        token: [],
        tokenPCMap: {},
        tokenStrikeMap: {},
        strikePriceIndexMap: {},
    },
    bankNifty: {
        data: DefaultIndicesData,
        strikePrice: [],
        token: [],
        tokenPCMap: {},
        tokenStrikeMap: {},
    },
    finNifty: {
        data: DefaultIndicesData,
        strikePrice: [],
        token: [],
        tokenPCMap: {},
        tokenStrikeMap: {},
    },
    midcpNifty: {
        data: DefaultIndicesData,
        strikePrice: [],
        token: [],
        tokenPCMap: {},
        tokenStrikeMap: {},
    },
};

const indicesSlice = createSlice({
    name: "indices",
    initialState,
    reducers: {
        fetchIndices(state, action) {
            state.indices = action.payload;
        },

        updateIndices(state, action) {
            state.nifty.strikePrice = action.payload.strikeList;
            state.nifty.token = Object.keys(action.payload.tokenPCMap);
            state.nifty.tokenPCMap = action.payload.tokenPCMap;
            state.nifty.tokenStrikeMap = action.payload.tokenStrikeMap;
            let extraElements =
                state.nifty.data.length - action.payload.strikeList.length;
            if (extraElements > 0) {
                state.nifty.data.splice(
                    action.payload.strikeList.length - extraElements,
                    extraElements
                );
            } else if (extraElements < 0) {
                for (let i = 0; i < -extraElements; i++) {
                    state.nifty.data.push({ ...state.dummyData });
                }
            }
            for (let i = 0; i < action.payload.strikeList.length; i++) {
                state.nifty.data[i]["strike"] = action.payload.strikeList[i];
                state.nifty.strikePriceIndexMap[action.payload.strikeList[i]] =
                    i;
            }
        },

        addIndicesData(state, action) {
            let keys = Object.keys(action.payload);
            let strikePrice = state.nifty.tokenStrikeMap[keys[0]];
            let peCe = state.nifty.tokenPCMap[keys[0]];
            for (let i = 0; i < state.nifty.data.length; i++) {
                if (state.nifty.data[i]["strike"] === strikePrice) {
                    state.nifty.data[i][peCe + "LTP"] = (
                        action.payload[keys[0]]["last_traded_price"] / 100
                    ).toFixed(2);
                    state.nifty.data[i][peCe + "Volume"] = (
                        action.payload[keys[0]]["volume_trade_for_the_day"] /
                        100000
                    ).toFixed(2);
                    state.nifty.data[i][peCe + "OI"] = (
                        action.payload[keys[0]]["open_interest"] / 100000
                    ).toFixed(2);
                }
            }
            // state.nifty.data.push(action.payload.data);
        },

        addIndicesDataSingleFetch(state, action) {
            for (let i = 0; i < action.payload["data"].length; i++) {
                let token = action.payload["data"][i]["symbolToken"];
                let strikePrice = state.nifty.tokenStrikeMap[token];
                let index = state.nifty.strikePriceIndexMap[strikePrice];
                let peCe = state.nifty.tokenPCMap[token];
                state.nifty.data[index][peCe + "LTP"] =
                    action.payload["data"][i]["ltp"].toFixed(2);
                state.nifty.data[index][peCe + "Volume"] = (
                    action.payload["data"][i]["tradeVolume"] / 100000
                ).toFixed(2);
                state.nifty.data[index][peCe + "OI"] = (
                    action.payload["data"][i]["opnInterest"] / 100000
                ).toFixed(2);
            }
        },

        deleteIndices(state) {
            state.indices = {};
        },
    },
});

export const {
    fetchIndices,
    updateIndices,
    addIndicesData,
    addIndicesDataSingleFetch,
    deleteIndices,
} = indicesSlice.actions;

export default indicesSlice.reducer;
