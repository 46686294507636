import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { usePagination } from "../features/usePagination";
import {
    MdOutlineNavigateBefore,
    MdOutlineSkipPrevious,
    MdOutlineNavigateNext,
    MdOutlineSkipNext,
} from "react-icons/md";
import "../styles/pagination.scss";

const Pagination = (props) => {
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [tempPageNumber, setTempPageNumber] = useState(0);

    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    useEffect(() => {
        setTotalPageCount(Math.ceil(totalCount / pageSize));
        setTempPageNumber(currentPage);
        // eslint-disable-next-line
    }, [paginationRange]);

    let lastPage = paginationRange[paginationRange.length - 1];

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const changeToFirst = () => {
        onPageChange(1);
    };

    const changeToLast = () => {
        onPageChange(lastPage);
    };

    const onKeyDownPageNumberField = (e) => {
        if (
            e.key === "Enter" &&
            tempPageNumber <= totalPageCount &&
            tempPageNumber !== "" &&
            tempPageNumber !== 0
        ) {
            onPageChange(parseInt(tempPageNumber));
            e.preventDefault();
            e.target.blur();
        }
    };

    const onBlurPageNumberField = (e) => {
        if (tempPageNumber === "" || tempPageNumber === 0) {
            setTempPageNumber(currentPage);
        } else if (Number(tempPageNumber) && tempPageNumber <= totalPageCount) {
            onPageChange(parseInt(tempPageNumber));
        }
    };

    const onChangePageNumberField = (e) => {
        let pageNumber = e.target.value;
        if (
            (Number(pageNumber) && pageNumber <= totalPageCount) ||
            pageNumber === ""
        ) {
            setTempPageNumber(pageNumber);
        }
    };

    return (
        <ul className="pagination-container">
            <MdOutlineSkipPrevious
                className={classnames("pagination-arrow", {
                    disabled: currentPage === 1,
                })}
                onClick={changeToFirst}
            />
            <MdOutlineNavigateBefore
                className={classnames("pagination-arrow", {
                    disabled: currentPage === 1,
                })}
                onClick={onPrevious}
            />
            <li className="pagination-item">
                <span>Page </span>
                <span>
                    <input
                        type="text"
                        onKeyDown={onKeyDownPageNumberField}
                        onChange={onChangePageNumberField}
                        onBlur={onBlurPageNumberField}
                        onClick={(e) => setTempPageNumber("")}
                        value={tempPageNumber}
                        className="pageination-input"
                    />
                </span>
                <span> of {totalPageCount}</span>
            </li>
            <MdOutlineNavigateNext
                className={classnames("pagination-arrow", {
                    disabled: currentPage === lastPage,
                })}
                onClick={onNext}
            />
            <MdOutlineSkipNext
                className={classnames("pagination-arrow", {
                    disabled: currentPage === lastPage,
                })}
                onClick={changeToLast}
            />
        </ul>
    );
};

export default Pagination;
