import React from "react";
import moneyUpLogo from "../assets/images/money-up-logo.png";
import Styles from "../styles/HomepageStyles.module.css";
import { Link } from "react-router-dom";
import liveClassesLogo from "../assets/images/live-classes-logo.png";
import youtubeLogo from "../assets/images/youtube-logo.png";
import courseLogo from "../assets/images/course-logo.png";
import fnoOiImage from "../assets/images/fnoOiImage.png";
// import people1 from "../assets/images/people1.svg";
// import people2 from "../assets/images/people2.svg";
// import { IoStar } from "react-icons/io5";
// import ThunderLogo from "../assets/images/thunder.png";
// import { HiArrowLongDown } from "react-icons/hi2";
// import basicToAdvance from "../assets/images/BasicToAdvance.png";

const Homepage = () => {
    return (
        <div className={Styles.homepage}>
            <div className={Styles.firstSection}>
                <div className={Styles.firstSectionHeader}>
                    <span className={Styles.oneLiner}>
                        Learn
                        <br />
                        track,
                        <br />
                        trade
                    </span>
                    <span className={Styles.moneyLogo}>
                        <img
                            src={moneyUpLogo}
                            alt="Money Up Logo"
                            className={Styles.moneyLogo}
                        />
                    </span>
                </div>
                <div className={Styles.firstSectionContent}>
                    SUYA BROKERAGE, your ultimate destination for stock market
                    enthusiasts who want to master the art of trading,
                    understand market psychology, leverage price action, Track
                    Stock data real time and Follow Institutional Investor
                    Movement{" "}
                </div>
                <div className={Styles.firstSectionFooter}>
                    <span className={Styles.applyNow}>
                        <Link to="/register">Apply Now</Link>
                    </span>
                </div>
            </div>
            {/* <div className={Styles.secondSection}>
                <div className={Styles.secondSectionHeader}>
                    <span>Why</span>
                    <img
                        src={people1}
                        className={Styles.peopleLogo}
                        alt="People1"
                    />
                    <img
                        src={people2}
                        className={Styles.peopleLogo}
                        alt="People2"
                    />
                    <span>Enroll In Our &nbsp;</span>
                    <span>Programs?</span>
                </div>
                <div className={Styles.secondSectionContent}>
                    <div className={Styles.secondSectionContentFirstPart}>
                        <span>Self - Paced Program</span>
                        <span>Simple Online Enrollment</span>
                        <span className={Styles.secondSectionApplyNow}>
                            <div>
                                <Link to="/signup">Apply Now</Link>
                            </div>
                            <HiArrowLongDown />
                        </span>
                    </div>
                    <div className={Styles.secondSectionContentSecondPart}>
                        <span> Pocket Friendly</span>
                    </div>
                </div>
                <div className={Styles.secondSectionFooter}>
                    <div className={Styles.secondSectionFooterFirstPart}>
                        <div className={Styles.basicToAdvance}>
                            <img src={basicToAdvance} alt="Basic to Advance" />
                            <div>
                                Basic To <br />
                                Advance
                            </div>
                        </div>
                        <div className={Styles.learn}>
                            <h1>Learn</h1>
                            <div className={Styles.learnContent}>
                                Basic of Technical analysis:
                                <br />
                                What is stock market &
                                <br />
                                How it works ?
                            </div>
                            <div className={Styles.learnCategory}>
                                <span>Basic</span>
                                <span>Intermediate</span>
                                <span>Advance</span>
                            </div>
                        </div>
                        <div className={Styles.track}>
                            <h1>Track</h1>
                            <div className={Styles.trackContent}>
                                Track stock market with our Free tools Advance
                                stock Data, Options chain, FII DII DATA
                            </div>
                            <div className={Styles.trackCategory}>
                                <span>Free</span>
                            </div>
                        </div>
                    </div>
                    <div className={Styles.trade}>
                        <h1>
                            <IoStar />
                            <Link to="/thunderOne">Trade</Link>
                        </h1>
                        <div className={Styles.thunderOne}>
                            Thunder
                            <img
                                src={ThunderLogo}
                                className={Styles.thunderLogo}
                                alt="Thunder Logo"
                            />
                            ONE
                        </div>
                        <div className={Styles.tradeContent}>
                            The Better for you Trading Terminals
                        </div>
                    </div>
                </div>
            </div> */}
            <div className={Styles.secondSection}>
                <div className={Styles.secondSectionHeader}>
                    <div>Learn</div>
                    <div>Track</div>
                    <div>Trade</div>
                </div>
                <div className={Styles.secondSectionContent}>
                    <div className={Styles.contentSectionHeader}>
                        Learn all about the stock market
                    </div>
                    <div>
                        with our expert-led courses, live trading classes, and
                        self-help psychological videos
                    </div>
                </div>
                <div className={Styles.secondSectionFooter}>
                    <div className={Styles.leftSecondSection}>
                        <div className={Styles.liveClassSection}>
                            <Link to="/register">
                                <img
                                    src={liveClassesLogo}
                                    className={Styles.liveClassesLogo}
                                    alt="Live Class Logo"
                                />
                            </Link>
                            <div className={Styles.learnContent}>
                                <div className={Styles.footerCardsHeader}>
                                    Live Market Class
                                </div>
                                <div>
                                    online sessions on risk management , options
                                    trading, technical analysis and more
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={Styles.rightSecondSection}>
                        <div className={Styles.courseSection}>
                            <div className={Styles.learnContent}>
                                <div className={Styles.footerCardsHeader}>
                                    Courses
                                </div>
                                <div>
                                    Self-Help courses on options Trading , stock
                                    market, forex and crypto market
                                </div>
                            </div>
                            <Link to="/register">
                                <img
                                    src={courseLogo}
                                    className={Styles.courseLogo}
                                    alt="Course Logo"
                                />
                            </Link>
                        </div>
                        <div className={Styles.youtubeSection}>
                            <img
                                src={youtubeLogo}
                                className={Styles.youtubeLogo}
                                alt="Youtube Logo"
                                onClick={() =>
                                    window.open(
                                        "https://www.youtube.com/@Suyabrokerage",
                                        "_blank"
                                    )
                                }
                            />
                            <div className={Styles.learnContent}>
                                <div className={Styles.footerCardsHeader}>
                                    Quick videos
                                </div>
                                <div>
                                    Learn about stocks, mutual Funds, IPOs,
                                    crypto , Forex and more
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={Styles.thirdSection}>
                <div className={Styles.leftThirdSection}>
                    <img src={fnoOiImage} alt="FNO Open Interest" />
                </div>
                <div className={Styles.rightThirdSection}>
                    <div className={Styles.thirdSectionHeader}>
                        Suya for smart traders
                    </div>
                    <div>
                        track Right, trade Now in Stocks, options & Futures,
                        though data driven trading.
                    </div>
                    <div className={Styles.thirdSectionInternalContainer}>
                        <div className={Styles.thirdSectionInternal}>
                            <div className={Styles.thirdSectionInternalHeader}>
                                FII/DII Data
                            </div>
                            <div>
                                Daily data update |Best for beginners |
                                Sentiment analyzer.
                            </div>
                        </div>
                        <div className={Styles.thirdSectionInternal}>
                            <div className={Styles.thirdSectionInternalHeader}>
                                Cash Market Data
                            </div>
                            <div>
                                Long term view | Daily summary | Historical
                                data.
                            </div>
                        </div>
                        <div className={Styles.thirdSectionInternal}>
                            <div className={Styles.thirdSectionInternalHeader}>
                                F&O Participant wise open interest
                            </div>
                            <div>
                                Daily Buy/sell data | index future | oi index.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Homepage;
