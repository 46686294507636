import { createSlice } from "@reduxjs/toolkit";
import { dummyIndiceSummary } from "../data/Constants";

const initialState = {
    fiiDii: [],
    fiiFnO: [],
    perDay: {
        NIFTY: dummyIndiceSummary,
        BANKNIFTY: dummyIndiceSummary,
        FINNIFTY: dummyIndiceSummary,
        MIDCPNIFTY: dummyIndiceSummary,
    },
};

const fiiSlice = createSlice({
    name: "fii",
    initialState,
    reducers: {
        updateFiiDii(state, action) {
            state.fiiDii = action.payload;
        },
        updateFiiFnO(state, action) {
            state.fiiFnO = action.payload;
        },
        updatePerDay(state, action) {
            let symbol_name = action.payload["symbol_name"];
            state.perDay[symbol_name] = action.payload;
        },
    },
});

export const { updateFiiDii, updateFiiFnO, updatePerDay } = fiiSlice.actions;

export default fiiSlice.reducer;
