import React, { useState } from "react";
import Styles from "../styles/FIIBaseStyles.module.css";
import FIIOptionTable from "../components/FIIOptionTable";
import FiiDii from "../components/FiiDii";
import FIIFnO from "../components/FIIFnO";
import CashMarketLongTerm from "../components/CashMarketLongTerm";

const FIIBase = () => {
    const [selectedTable, useSelectedTable] = useState(1);

    return (
        <div className={Styles.FIIBase}>
            <FIIOptionTable selectTable={useSelectedTable} />
            {selectedTable === 1 ? (
                <FiiDii />
            ) : selectedTable === 2 ? (
                <CashMarketLongTerm />
            ) : selectedTable === 3 ? (
                <FIIFnO />
            ) : (
                <div>Fii Dii History</div>
            )}
        </div>
    );
};

export default FIIBase;
