import React from "react";
import Styles from "../styles/IndicesSummaryStyles.module.css";
import niftyFiftyLogo from "../assets/images/niftyFiftyLogo.png";
import bankNiftyLogo from "../assets/images/bankNiftyLogo.png";
import { IoCaretDownSharp, IoCaretUpSharp } from "react-icons/io5";

const IndicesSummary = (props) => {
    const renderIndiceLogo = (indiceName) => {
        if (indiceName === "NIFTY") {
            return <img src={niftyFiftyLogo} alt="index logo" />;
        } else {
            return <img src={bankNiftyLogo} alt="index logo" />;
        }
    };
    const renderStockDataChange = (change, changePer) => {
        if (change > 0) {
            return (
                <span className={Styles.trendGreen}>
                    <IoCaretUpSharp size={15} className={Styles.arrowUp} />
                    {change}
                    {" ("}
                    {changePer}
                    {")"}
                </span>
            );
        } else {
            return (
                <span className={Styles.trendRed}>
                    <IoCaretDownSharp size={15} className={Styles.arrowDown} />
                    {change}
                    {" ("}
                    {changePer}
                    {")"}
                </span>
            );
        }
    };

    return (
        <div className={Styles.indicesWrapper}>
            {renderIndiceLogo(props.name)}
            <div className={Styles.indicesWrapperData}>
                <div>{props.name}</div>
                <div>{props.lastPrice}</div>
                <div>
                    {renderStockDataChange(props.change, props.changePercent)}
                </div>
            </div>
        </div>
    );
};

export default IndicesSummary;
