import { configureStore, combineReducers } from "@reduxjs/toolkit";
import indicesReducer from "./indices/indicesSlice";
import fiiReducer from "./fiiSlice";

const reducer = combineReducers({
    indices: indicesReducer,
    fii: fiiReducer,
});

const store = configureStore({
    reducer,
    devTools: process.env.REACT_APP_ENVIRONMENT !== "production",
});

export default store;
