import React, { useState } from "react";
import Styles from "../styles/RegisterStyles.module.css";
import { MenuItem, Button, TextField, Select } from "@mui/material";
import axios from "axios";
import { ADDUSERURL } from "../data/Constants";
import { useNavigate } from "react-router-dom";

const defaultFormData = {
    Name: "",
    Email: "",
    Mobile: "",
    HasWhatsapp: "true",
    City: "",
    TradingKnowledge: "-1",
};

const tradingKnowledgeValues = [
    {
        value: "-1",
        label: "Beginner",
    },
    {
        value: "0",
        label: "Intermediate",
    },
    {
        value: "1",
        label: "Advanced",
    },
];

const Register = () => {
    const [formState, setFormState] = useState(defaultFormData);
    const navigate = useNavigate();

    const gotToHomePage = () => {
        navigate("/");
    };

    const changeFormState = (e) => {
        if (e.target.name === "Email" || e.target.name === "Mobile") {
            if (e.target.value.includes(" ")) {
                e.target.value = e.target.value.replace(/\s/g, "");
            }
        }
        setFormState({ ...formState, [e.target.name]: e.target.value });
    };

    const emptyCheck = (value) => {
        if (value === "") {
            return true;
        }
        return false;
    };

    const validateName = (name) => {
        const nameRegex = /[^A-Za-z ]/;
        if (nameRegex.test(name)) {
            return false;
        }
        return true;
    };

    const validateEmail = (email) => {
        // eslint-disable-next-line
        const emailRegex = /[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/;
        let result = email.match(emailRegex);
        // eslint-disable-next-line
        if (result == email) {
            return true;
        } else {
            return false;
        }
    };

    const validateMobile = (mobile) => {
        const mobileRegex = /^\d{10}$/;
        return mobileRegex.test(mobile);
    };

    const sendUserDatatoAdd = async (data) => {
        try {
            console.log(data);
            let response = await axios.request({
                url: ADDUSERURL,
                method: "POST",
                data: data,
            });
            console.log(response.data);
            if (response.data.message === "Added Successfully") {
                gotToHomePage();
                alert("Registered Successfully");
            } else if (response.data.message === "Data Already Present") {
                let presentKeys = Object.keys(response.data.detailedMessage);
                console.log(presentKeys);
                let alertText = "Already Present Fields\n";
                for (let key in presentKeys) {
                    alertText += presentKeys[key] + " Already Present\n";
                }
                alert(alertText);
            }
        } catch (error) {
            // console.error(error);
        }
    };

    const registerData = () => {
        let alertText = "";
        if (emptyCheck(formState.Name)) {
            alertText += "Name cannot be empty\n";
        } else if (!validateName(formState.Name)) {
            alertText += "Name can only contain Aplhabets and Spaces\n";
        }

        if (emptyCheck(formState.Email)) {
            alertText += "Email cannot be empty\n";
        } else if (!validateEmail(formState.Email)) {
            alertText += "Email not valid\n";
        }

        if (emptyCheck(formState.Mobile)) {
            alertText += "Mobile cannot be empty\n";
        } else if (!validateMobile(formState.Mobile)) {
            alertText += "Mobile not valid\n";
        }

        if (emptyCheck(formState.City)) {
            alertText += "City cannot be empty\n";
        }

        if (alertText !== "") {
            clearFields();
            alert(alertText);
        } else {
            sendUserDatatoAdd(formState);
        }
    };

    const clearFields = () => {
        setFormState(defaultFormData);
    };

    return (
        <div className={Styles.RegisterBase}>
            <div className={Styles.registerHeader}>Welcome to Suya</div>
            <div className={Styles.innerRegister}>
                <div>
                    <TextField
                        name="Name"
                        label="Name"
                        variant="standard"
                        value={formState.Name}
                        onChange={changeFormState}
                        sx={{
                            width: "15vw",
                            input: {
                                color: "white",
                                fontFamily: '"Montserrat", sans-serif',
                            },
                            "& .MuiInput-underline:before": {
                                borderBottomColor: "white",
                            },
                            "& .MuiInput-underline:after": {
                                borderBottomColor: "white",
                            },
                            "&& .MuiInput-root:hover::before": {
                                borderBottomColor: "white",
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: "white",
                                fontFamily: '"Montserrat", sans-serif',
                            },
                        }}
                        inputProps={{ autoComplete: "off" }}
                    />
                </div>
                <div>
                    <TextField
                        name="Email"
                        label="Email"
                        variant="standard"
                        value={formState.Email}
                        onChange={changeFormState}
                        sx={{
                            width: "15vw",
                            input: {
                                color: "white",
                                fontFamily: '"Montserrat", sans-serif',
                            },
                            "& .MuiInput-underline:before": {
                                borderBottomColor: "white",
                            },
                            "& .MuiInput-underline:after": {
                                borderBottomColor: "white",
                            },
                            "&& .MuiInput-root:hover::before": {
                                borderBottomColor: "white",
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: "white",
                                fontFamily: '"Montserrat", sans-serif',
                            },
                        }}
                        inputProps={{ autoComplete: "off" }}
                    />
                </div>
                <div>
                    <TextField
                        name="Mobile"
                        label="Mobile"
                        variant="standard"
                        type="number"
                        value={formState.Mobile}
                        onChange={changeFormState}
                        sx={{
                            width: "15vw",
                            input: {
                                color: "white",
                                fontFamily: '"Montserrat", sans-serif',
                            },
                            "& .MuiInput-underline:before": {
                                borderBottomColor: "white",
                            },
                            "& .MuiInput-underline:after": {
                                borderBottomColor: "white",
                            },
                            "&& .MuiInput-root:hover::before": {
                                borderBottomColor: "white",
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: "white",
                                fontFamily: '"Montserrat", sans-serif',
                            },
                        }}
                        inputProps={{ autoComplete: "off" }}
                    />
                </div>
                <div>
                    <TextField
                        name="City"
                        label="City"
                        variant="standard"
                        value={formState.City}
                        onChange={changeFormState}
                        sx={{
                            width: "15vw",
                            input: {
                                color: "white",
                                fontFamily: '"Montserrat", sans-serif',
                            },
                            "& .MuiInput-underline:before": {
                                borderBottomColor: "white",
                            },
                            "& .MuiInput-underline:after": {
                                borderBottomColor: "white",
                            },
                            "&& .MuiInput-root:hover::before": {
                                borderBottomColor: "white",
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: "white",
                                fontFamily: '"Montserrat", sans-serif',
                            },
                        }}
                        inputProps={{ autoComplete: "off" }}
                    />
                </div>
                <div>
                    <Select
                        name="TradingKnowledge"
                        label="Trading Knowledge"
                        value={formState.TradingKnowledge}
                        onChange={changeFormState}
                        sx={{
                            width: "15vw",
                            backgroundColor: "white",
                            fontFamily: '"Montserrat", sans-serif',
                        }}
                    >
                        {tradingKnowledgeValues.map((option) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                sx={{ fontFamily: '"Montserrat", sans-serif' }}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div className={Styles.buttonContainer}>
                    <Button
                        variant="contained"
                        onClick={registerData}
                        size="large"
                        sx={{
                            fontFamily: '"Montserrat", sans-serif',
                            borderRadius: "10px",
                        }}
                    >
                        Register
                    </Button>
                    <Button
                        variant="contained"
                        onClick={clearFields}
                        size="large"
                        sx={{
                            fontFamily: '"Montserrat", sans-serif',
                            borderRadius: "10px",
                        }}
                    >
                        Clear
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Register;
