import React, { useEffect } from "react";
import axios from "axios";
import Marquee from "react-fast-marquee";
import Styles from "../styles/NavbarStyles.module.css";
import LogoText from "../assets/images/logo-text.png";
import LogoImage from "../assets/images/logo-img.png";
// import ThunderLogo from "../assets/images/thunder.png";
import { IoCaretDownSharp, IoCaretUpSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

const rapidApiOptions = {
    method: "GET",
    url: "https://latest-stock-price.p.rapidapi.com/any",
    headers: {
        "X-RapidAPI-Key": "01a222380fmsh85cd31b7c03a586p1ce088jsn7221ca30daff",
        "X-RapidAPI-Host": "latest-stock-price.p.rapidapi.com",
    },
};

const Navbar = () => {
    const [stocksData, setStocksData] = React.useState(null);

    useEffect(() => {
        fetchStock();
    }, []);

    const fetchStock = async () => {
        try {
            const response = await axios.request(rapidApiOptions);
            setStocksData(response.data);
        } catch (error) {
            // console.error(error);
        }
    };

    const renderStocksDataBanner = () => {
        if (stocksData !== null && stocksData.length !== 0) {
            return (
                <div className={Styles.announcement}>
                    <Marquee
                        speed={60}
                        pauseOnHover
                        style={{ position: "sticky", top: "0px", zIndex: "1" }}
                    >
                        {stocksData.map((stockData) => (
                            <div
                                className={Styles.announcementText}
                                key={stockData.symbol}
                            >
                                <span>{stockData.symbol}</span>
                                <span>&#8377;{stockData.lastPrice}</span>
                                {renderStockDataChange(stockData.change)}
                            </div>
                        ))}
                    </Marquee>
                </div>
            );
        } else {
            // fetchStock();
            return <div className={Styles.greeting}>{renderGreetings()}</div>;
        }
    };

    const renderStockDataChange = (change) => {
        if (change > 0) {
            return (
                <span className={Styles.trendGreen}>
                    <IoCaretUpSharp size={15} className={Styles.arrowUp} />
                    {(Math.round(change * 100) / 100).toFixed(2)}
                </span>
            );
        } else {
            return (
                <span className={Styles.trendRed}>
                    <IoCaretDownSharp size={15} className={Styles.arrowDown} />
                    {(Math.round(change * 100) / 100).toFixed(2)}
                </span>
            );
        }
    };

    const renderGreetings = () => {
        const date = new Date();
        const currentHour = date.getHours();
        let greetingMessage = "Good";
        if (currentHour >= 6 && currentHour < 12) {
            greetingMessage = greetingMessage + " Morning";
        } else if (currentHour >= 12 && currentHour < 16) {
            greetingMessage = greetingMessage + " Afternoon";
        } else if (currentHour >= 16 && currentHour < 20) {
            greetingMessage = greetingMessage + " Evening";
        } else {
            greetingMessage = "Nighty Night";
        }
        return <span>{greetingMessage}</span>;
    };

    return (
        <div className={Styles.navbar}>
            <div className={Styles.ticker}>{renderStocksDataBanner()}</div>
            <div className={Styles.header}>
                <div className={Styles.logo}>
                    <Link to="/">
                        <img
                            src={LogoImage}
                            className={Styles.logoImage}
                            alt="Suya Logo"
                        />
                    </Link>
                    <Link to="/">
                        <img
                            src={LogoText}
                            className={Styles.logoText}
                            alt="Suya Logo"
                        />
                    </Link>
                </div>
                <div className={Styles.menu}>
                    {/* <Link to="/thunderone">
                        <span className={Styles.thunderOne}>
                            Thunder{" "}
                            <img
                                src={ThunderLogo}
                                className={Styles.thunderLogo}
                                alt="Thunder Logo"
                            />{" "}
                            ONE
                        </span>
                    </Link> */}
                    <Link to="/">
                        <span className={Styles.menuContent}>Home</span>
                    </Link>
                    <Link to="/option-chain">
                        <span className={Styles.menuContent}>Option chain</span>
                    </Link>
                    <Link to="/fii-dii">
                        <span className={Styles.menuContent}>FII DII</span>
                    </Link>
                    {/* <span className={Styles.menuContent}>
                        <Link to="/oi">OI</Link>
                    </span> */}
                    <Link to="/">
                        <span className={Styles.menuContent}>Product</span>
                    </Link>
                </div>
                <div className={Styles.authentication}>
                    <span className={Styles.login}>
                        <Link to="/login">Login</Link>
                    </span>
                    <span className={Styles.signup}>
                        <Link to="/signup">Sign up</Link>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
