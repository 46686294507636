import React, { useCallback, useState } from "react";
import moment from "moment";
import Styles from "../styles/FIIOptionTableStyles.module.css";
import { useSelector } from "react-redux";

const FIIOptionTable = (props) => {
    const fiiData = useSelector((state) => state.fii);
    const [selectedTable, setSelectedTable] = useState(1);

    const handleChange = useCallback(
        (data) => {
            props.selectTable(data);
            setSelectedTable(data);
        },
        [props]
    );

    return (
        <div className={Styles.FIIOptionTable}>
            <div className={Styles.optionTable}>
                <span onClick={() => handleChange(1)}>
                    Capital Market Daily
                </span>
                <span onClick={() => handleChange(2)}>
                    Cash Market Long Term View
                </span>
                <span onClick={() => handleChange(3)}>Future and Options</span>
                {/* <span onClick={() => handleChange(4)}>FII DII History</span> */}
            </div>
            <div className={Styles.optionDate}>
                {selectedTable === 1 ? (
                    <span>
                        {moment(fiiData?.fiiDii[0]?.Date).format(
                            "Do MMMM YYYY"
                        )}
                    </span>
                ) : selectedTable === 3 ? (
                    <span>
                        {moment(fiiData?.fiiFnO?.FII?.Date).format(
                            "Do MMMM YYYY"
                        )}
                    </span>
                ) : (
                    <div></div>
                )}
            </div>
        </div>
    );
};

export default FIIOptionTable;
